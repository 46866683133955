<template>
  <div class="page">
    <template v-if="list && list.length">
        <div class="page-c3">
            <div class="page-c3-one" v-for="(item,index) in list" :key="index" @click="LinkAct(item)">
                <div class="top">
                    <img v-if="item.banners" :src="item.banners" > 
                    <img v-else src="@/assets/img/empty.png" >
                </div>   
                <div class="bot">
                    <p class="bot-txt1 f-toe">{{item.title}}</p>
                    <p class="bot-txt2">时间：{{item.dateTime}}</p>
                    <p class="bot-txt2" >{{item.company?item.company.name:'主办活动'}}</p>
                </div>
                <div class="live_tag" v-if="item.hasLive">
                  <div class="ing" v-if="item.liveStateIndex==1"><span></span>直播中</div>
                  <div class="is_live" v-else-if="item.liveStateIndex==2"><i class="iconfont icon-shipinmianxing"></i>有直播</div>
                </div>
            </div>
        </div>
    </template>
    <div class="no_more mar_bot2" v-if="loading">
      <p>加载中...</p>
    </div>
    <div class="no_more mar_bot2" v-else-if="finished">
      <p>没有更多了</p>
    </div>
    <div class="more mar_bot2" @click="moreClick" v-else-if="list && list.length">
      <p>加载更多</p>
    </div>
    <div class="no_more mar_bot2" v-else>暂无数据！</div>
  
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
export default {
  name: "live-control-start",
  props:{
    isReg:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      list: [],
      totalCount:0,
      pageIndex:1,
      pageSize:15,
      finished:false,
      loading:false,
    };
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
      user:'getUser'
    }),
  },
  created() {
    this.getData();
  },
  methods: {
    ...mapActions({
      graphqlPost:'graphqlPostByZXS',
      LinkAct:'linkTo/LinkAct'
    }),
    getData(){
      if(this.isReg){
        this.getRegData();
      }else{
        this.getFollowData();
      }
    },
    async getRegData() {
      let query = `
        query($query:QueryInput!){
          activityRegQuery{
            query(query:$query){
                hasNextPage
                pageIndex
                totalCount
                items{
                  id
                  activity{
                    auditState
                    id
                    title
                    banners
                    startTime	 #开始时间
                    endTime		 #结束时间
                    acvitityType #活动类型，0/ExternalLink：外链、1/Live：直播、 2/Registration：报名
                    externalLink #外链地址
                    stateIndex #活动进行状态 1：进行中、2：未开始：3：已结束
                    liveStateIndex  
                    liveAuditState
                    hasLive
                    company{
                      id
                      name
                    }
                  }
                }
              }
            }
        }
      `;
      let where = {
        Equal: {
          n: "userId",
          v: this.user.userId,
        },
        EqualA: {
          n: "exhibitionId",
          v: this.exhibitionId,
        },
      };
      let order = [
        {
          N: "createAt",
          v: 0,
        },
      ];
      let opt = {
        query: query,
        variables: {
          query: {
            page: this.pageIndex,
            size: this.pageSize,
            where: JSON.stringify(where),
            order: JSON.stringify(order),
          },
        },
      };
      let data = await this.graphqlPost(opt);
      let res = JSON.parse(
        JSON.stringify(data.data.activityRegQuery.query)
      );
      // console.log('报名活动',data);
      let arr=[];
      res.items.forEach(e=>{
        if(e.activity){
          arr.push(e.activity)
        }
      })
      res.items=arr;
      res.items.forEach(e=>{
        if(e){
          if(e.banners&&e.banners.indexOf('[')!=-1){
            let banArr=JSON.parse(e.banners);
            e.banners=banArr[0];
          }
          let str=moment(e.startTime).format("YYYY/MM/DD");
          str+='~'+moment(e.endTime).format("MM/DD");
          e.dateTime=str;
        }
      })
      if(this.pageIndex==1){
        this.list=res.items
      }else{
        this.list=[...new Set([...this.list,...res.items])];
      }
      // console.log(this.list);
      this.loading=false;
      if (!res.hasNextPage) {
        this.finished = true;
      }
    },
    async getFollowData() {
      let query = `
        query($query:QueryInput!){
          activityFollowQuery{
            query(query:$query){
                hasNextPage
                pageIndex
                totalCount
                items{
                  activity{
                    auditState
                    id
                    title
                    banners
                    startTime	 #开始时间
                    endTime		 #结束时间
                    acvitityType #活动类型，0/ExternalLink：外链、1/Live：直播、 2/Registration：报名
                    externalLink #外链地址
                    stateIndex #活动进行状态 1：进行中、2：未开始：3：已结束
                    liveStateIndex  
                    liveAuditState
                    hasLive
                    company{
                      id
                      name
                    }
                  }
                }
              }
            }
        }
      `;
      let where = {
        Equal: {
          n: "userId",
          v: this.user.userId,
        },
        EqualA: {
          n: "exhibitionId",
          v: this.exhibitionId,
        },
      };
      let order = [
        {
          N: "createAt",
          v: 0,
        },
      ];
      let opt = {
        query: query,
        variables: {
          query: {
            page: this.pageIndex,
            size: this.pageSize,
            where: JSON.stringify(where),
            order: JSON.stringify(order),
          },
        },
      };
      let data = await this.graphqlPost(opt);
      let res = JSON.parse(
        JSON.stringify(data.data.activityFollowQuery.query)
      );
      // console.log('活动',res);
      let arr=[];
      res.items.forEach(e=>{
        arr.push(e.activity)
      })
      res.items=arr;
      res.items.forEach(e=>{
        if(e.banners&&e.banners.indexOf('[')!=-1){
          let banArr=JSON.parse(e.banners);
          e.banners=banArr[0];
        }
        let str=moment(e.startTime).format("YYYY/MM/DD");
        str+='~'+moment(e.endTime).format("MM/DD");
        e.dateTime=str;
      })
      if(this.pageIndex==1){
        this.list=res.items
      }else{
        this.list=[...new Set([...this.list,...res.items])];
      }
      // console.log(this.list);
      this.loading=false;
      if (!res.hasNextPage) {
        this.finished = true;
      }
    },
    //点击加载更多
    moreClick(){
      if (this.finished) return;
      this.pageIndex += 1;
      this.getData()
    },
  },
};
</script>

<style scoped lang="scss">
@import "@assets/scss/my-theme/mixin.scss";
.page{
    &-c3{
        display: flex;
        flex-wrap: wrap;
        padding:4px 20px 20px 20px;
        &-one{
            width: 309px;
            margin:0 20px 20px 0;
            border: solid 1px #ededed;
            cursor: pointer;
            position: relative;
            .live_tag{
              width: 62px;
              height: 20px;
              position: absolute;
              top: 10px;
              left: 10px;
              div{
                line-height: 24px;
                border-radius: 3px;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                color: #fff;
                .iconfont{
                  margin-right: 3px;
                  font-size: 12px;
                }
                span{
                  display: block;
                  background: #fff;
                  width: 8px;
                  height: 8px;
                  border-radius: 50%;
                  margin-right: 6px;
                }
                &.ing{
                  background-image: linear-gradient(90deg, 
                #ff416c 0%, 
                #ff4b2b 100%), 
              linear-gradient(
                #000, 
                #000);
                }
                &.is_live{
                  background-image: linear-gradient(90deg,#56ccf2,#2f80ed),linear-gradient(#000,#000);
                }
              }
            }
        }
        &-one:nth-child(3n+0){
           margin-right: 0;
        }
        .top{
            width: 100%;
            height: 174px;
            img{
                width: 100%;
                height: 100%;
                display: block;
            }
        }
        .bot{
           padding: 14px;
           height: 96px;
           display: flex;
           flex-direction: column;
           justify-content: space-between;
           &-txt1,&-txt2{
               overflow: hidden;
               text-overflow: ellipsis;
               white-space: nowrap;
           }
           &-txt2{
               font-size: 12px;
               color: #666;
           }
        }
        
    }
}
</style>
